import React, { useEffect, useState } from "react";
import { PageProps } from "gatsby";
import queryString from "query-string";
import Layout from "../../components/layout";
import Header from "../../components/header";
import InformationPanelHeader from "../../components/information_panel_header";
import InformationPanelDetail from "../../components/information_panel_detail";
import InformationFooter from "../../components/information_footer";

const InformationPage: React.FC<PageProps> = ({ location }) => {
  const { contentId, draftKey } = queryString.parse(location.search);
  
  const [data, setData] = useState<unknown>(null);
  useEffect(() => {
    fetch(`/api/post/${contentId}/draft/${draftKey}`)
      .then((res) => res.json())
      .then((res) => setData(res));
  }, []);

  // [unknown型とタイプガードと私](https://qiita.com/ma2saka/items/eb2d26236c20afb7f764)
  const hasInformation = (d: any): d is GatsbyTypes.MicrocmsPost => {
    if (!d) return false;
    if (d.title && typeof d.title === "string") {
      return true;
    }
    return false;
  };
  if (hasInformation(data)) {
    const info = data;
    return (
      <>
        <Header title={info.title ?? ""} />
        <Layout>
          <InformationPanelHeader info={info} />
          <InformationPanelDetail info={info} />
          <InformationFooter />
        </Layout>
      </>
    );
  }
  return null;
};

export default InformationPage;
